<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">{{current_page}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form" class="multi-col-validation">
              <v-select
                v-model="view_by"
                class="mx-2  mt-2"
                dense
                outlined
                label="View By"
                :items="['All','Deposit Slip']"
                :rules="rules.combobox_rule"
                @change="selected_data"
              ></v-select>
              <v-select
                v-model="month_of_id"
                class="mx-2"
                dense
                outlined
                label="Month Of"
                :items="month_of_items"
                item-value="id"
                item-text="month_of"
                :rules="rules.combobox_rule"
                @change="selected_data"
              ></v-select>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12"
                     v-if="(data_items.length>0 && view_by==='All'&&(position==='ADMIN'||position==='FINANCE'))||(data_items2.length>0 && view_by==='Deposit Slip')">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="print_data()"
                  v-if="!saving_data"
                >
                  Print
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-form>
            <v-data-table dense
                          :headers="headers3"
                          :items="data_items"
                          v-if="view_by==='Deposit Slip'"
            >
              <template v-slot:item="{ item }">
                <tr
                  @click="activerow(item)"
                  :class="{'info white--text': item.id===selectedDepositId}"
                >
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.date }}
                  </td>
                  <td>
                    {{ item.time }}
                  </td>
                  <td>
                    {{ item.bank!=null?item.bank.bank_code:'' }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                  <td>
                    {{ item.is_no_cash===1?'NO CASH':'CASH' }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2">
            <h2>Total: P {{total_amount}}</h2>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
                          v-if="view_by==='All'"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.deposit_slip!=null?item.deposit_slip.date:''}}
                  </td>
                  <td>
                    {{item.deposit_slip!=null? item.deposit_slip.time:'' }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount)}}
                  </td>
                  <td>
                    {{ item.deposit_slip!=null?item.deposit_slip.bank.bank_code:''}}
                  </td>
                  <td>
                    {{ remittances_category==='AUTO LOAN'?
                    (item.auto_loan!=null?item.auto_loan.name:item.credit_to):
                    (remittances_category==='CA'?(item.request_data!=null?item.request_data.payment_for:item.credit_to):
                    (remittances_category==='LOAN'?(item.loan_application!=null?(item.loan_application.employee!=null?item.loan_application.employee.last_name
                    +', '+item.loan_application.employee.first_name
                    +' '+item.loan_application.employee.middle_name:item.loan_application.name
                    ):item.credit_to):
                    (remittances_category==='OTHER RECEIVABLE'
                    ||remittances_category==='OFFICE RENTAL SEC DEPOSIT'?
                    (item.other_receivable!=null?(item.other_receivable.employee!=null?item.other_receivable.employee.last_name
                    +', '+item.other_receivable.employee.first_name
                    +' '+item.other_receivable.employee.middle_name:item.other_receivable.name
                    ):item.credit_to):
                    (remittances_category==='MOTOR FINANCE'?
                    (item.motor_finance!=null?(item.motor_finance.employee!=null?item.motor_finance.employee.last_name
                    +', '+item.motor_finance.employee.first_name
                    +' '+item.motor_finance.employee.middle_name:item.motor_finance.name
                    ):item.credit_to):item.credit_to))))
                    }}
                  </td>
                  <td>
                    {{ remittances_category==='AUTO LOAN'?
                    (item.auto_loan!=null?item.auto_loan.brand_car
                    +'( '+ item.auto_loan.unit+' )':'RETURN FUND - '+item.description):
                    (remittances_category==='CA'?(item.request_data!=null?item.request_data.description:
                    'RETURN FUND - '+item.description):
                    (remittances_category==='LOAN'?(item.loan_application!=null?item.loan_application.purpose:
                    'RETURN FUND - '+item.description):
                    (remittances_category==='OTHER RECEIVABLE'
                    ||remittances_category==='OFFICE RENTAL SEC DEPOSIT'?
                    (item.other_receivable!=null?item.other_receivable.purpose:
                    'RETURN FUND - '+item.description):
                    (remittances_category==='MOTOR FINANCE'?
                    (item.motor_finance!=null?item.motor_finance.purpose:''):
                    'RETURN FUND - '+item.description))))
                    }}
                  </td>

                  <td>
                    {{ item.particulars}}
                  </td>
                  <td>
                    {{ item.duration}}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table dense
                          :headers="headers2"
                          :items="data_items2"
                          v-else
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ remittances_category==='AUTO LOAN'?
                    (item.auto_loan!=null?item.auto_loan.name:item.credit_to):
                    (remittances_category==='CA'?(item.request_data!=null?item.request_data.payment_for:item.credit_to):
                    (remittances_category==='LOAN'?(item.loan_application!=null?(item.loan_application.employee!=null?item.loan_application.employee.last_name
                    +', '+item.loan_application.employee.first_name
                    +' '+item.loan_application.employee.middle_name:item.loan_application.name
                    ):item.credit_to):
                    (remittances_category==='OTHER RECEIVABLE'
                    ||remittances_category==='OFFICE RENTAL SEC DEPOSIT'?
                    (item.other_receivable!=null?(item.other_receivable.employee!=null?item.other_receivable.employee.last_name
                    +', '+item.other_receivable.employee.first_name
                    +' '+item.other_receivable.employee.middle_name:item.other_receivable.name
                    ):item.credit_to):
                    (remittances_category==='MOTOR FINANCE'?
                    (item.motor_finance!=null?(item.motor_finance.employee!=null?item.motor_finance.employee.last_name
                    +', '+item.motor_finance.employee.first_name
                    +' '+item.motor_finance.employee.middle_name:item.motor_finance.name
                    ):item.credit_to):item.credit_to))))
                    }}
                  </td>
                  <td>
                    {{ remittances_category==='AUTO LOAN'?
                    (item.auto_loan!=null?item.auto_loan.brand_car
                    +'( '+ item.auto_loan.unit+' )':'RETURN FUND - '+item.description):
                    (remittances_category==='CA'?(item.request_data!=null?item.request_data.description:
                    'RETURN FUND - '+item.description):
                    (remittances_category==='LOAN'?(item.loan_application!=null?item.loan_application.purpose:
                    'RETURN FUND - '+item.description):
                    (remittances_category==='OTHER RECEIVABLE'
                    ||remittances_category==='OFFICE RENTAL SEC DEPOSIT'?
                    (item.other_receivable!=null?item.other_receivable.purpose:
                    'RETURN FUND - '+item.description):
                    (remittances_category==='MOTOR FINANCE'?
                    (item.motor_finance!=null?item.motor_finance.purpose:''):
                    'RETURN FUND - '+item.description))))
                    }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                  <td>
                    {{ item.particulars }}
                  </td>
                  <td>
                    {{ item.duration }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      alert: false,
      saving_data: false,
      alert_message: '',
      month_of_id: '',
      view_by: '',
      total_amount: '0',
      headers: [
        {text: 'Date Deposited', value: 'date_of_deposit', sortable: false},
        {text: 'Time Deposited', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Bank Code', value: 'tin', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'tin', sortable: false},
        {text: 'Duration', value: 'tin', sortable: false},
      ],
      headers2: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'tin', sortable: false},
        {text: 'Duration', value: 'tin', sortable: false},
      ],
      headers3: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Date', value: 'date_of_deposit', sortable: false},
        {text: 'Time', value: 'tin', sortable: false},
        {text: 'Bank', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Type', value: 'tin', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      month_of_items: [],
      selectedDepositId: -1,
      current_page: '',
      remittances_category: '',
      depo_info: {},
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      switch (this.$router.currentRoute.name) {
        case "company-receivable-auto-loan-remittances":
          this.current_page = 'REMITTANCES (AUTO LOAN)'
          this.remittances_category = 'AUTO LOAN'
          break;
        case "company-receivable-cash-advance-remittances":
          this.current_page = 'REMITTANCES (CASH ADVANCE)'
          this.remittances_category = 'CA'
          break;
        case "company-receivable-loan-remittances":
          this.current_page = 'REMITTANCES (ACCOUNTING LOAN)'
          this.remittances_category = 'LOAN'
          break;
        case "company-receivable-other-receivable-remittances":
          this.current_page = 'REMITTANCES (OTHER RECEIVABLE)'
          this.remittances_category = 'OTHER RECEIVABLE'
          break;
        case 'company-receivable-office-sec-deposit-remittances':
          this.current_page = 'REMITTANCES (OFFICE RENTAL SEC DEPOSIT)'
          this.remittances_category = 'OFFICE RENTAL SEC DEPOSIT'
          break
        case "company-receivable-motor-finance-remittances":
          this.current_page = 'REMITTANCES (MOTOR FINANCE)'
          this.remittances_category = 'MOTOR FINANCE'
          break;
        case "company-receivable-bod-charge-remittances":
          this.current_page = 'REMITTANCES (BOD CHARGES)'
          this.remittances_category = 'BOD CHARGES'
          break;
      }
      this.initialize_company_receivable_remittances({
        category: this.remittances_category
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deposit_slip_receivable', ['initialize_company_receivable_remittances', 'view_remittances']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_data() {
        this.selectedDepositId = -1
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.view_remittances({
          view_by: this.view_by,
          category: this.remittances_category,
          month_of_id: this.month_of_id
        })
          .then(response => {
            this.data_items = response.data
            if (this.view_by === 'All') {
              var tto = 0;
              this.data_items.forEach(function (item) {
                tto += parseFloat(item.amount)
              });
              this.total_amount = this.formatPrice(tto)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      activerow(value) {
        this.depo_info = value
        this.total_amount = '0'
        this.data_items2 = this.remittances_category === 'AUTO LOAN' ? value.auto_loan_payment :
          (this.remittances_category === 'CA' ? value.cash_advance_payment :
            (this.remittances_category === 'LOAN' ? value.loan_application_payment :
              (this.remittances_category === 'OTHER RECEIVABLE' || this.remittances_category === 'OFFICE RENTAL SEC DEPOSIT' ? value.other_receivable_payment :
                (this.remittances_category === 'MOTOR FINANCE' ? value.motor_finance_payment : []))))
        this.selectedDepositId = value.id;
        if (this.data_items2.length > 0) {
          var tto = 0;
          this.data_items2.forEach(function (item) {
            tto += parseFloat(item.amount)
          });
          this.total_amount = this.formatPrice(tto)
        }
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []
          var options = this.remittances_category
          if (this.view_by === 'All') {
            widths = [40, 30, 35, 80, 95, 130, 40, 30]
            payments_array.push([
              {text: 'Date', alignment: 'left', style: 'main_info',},
              {text: 'Time', alignment: 'left', style: 'main_info'},
              {text: 'Amt', alignment: 'left', style: 'main_info'},
              {text: 'Bank Code', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Description', alignment: 'left', style: 'main_info'},
              {text: 'Prtclr', alignment: 'left', style: 'main_info'},
              {text: 'Duration', alignment: 'left', style: 'main_info'},
            ])
            this.data_items.forEach(function (item) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {
                  text: item.deposit_slip != null ? item.deposit_slip.date : '',
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.deposit_slip != null ? item.deposit_slip.time : '',
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.deposit_slip != null ? item.deposit_slip.bank.bank_code : '',
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: options === 'AUTO LOAN' ? (item.auto_loan != null ? item.auto_loan.name : item.credit_to) :
                    (options === 'CA' ? (item.request_data != null ? item.request_data.payment_for : '') :
                      (options === 'LOAN' ? (item.loan_application != null ? (item.loan_application.employee != null ? item.loan_application.employee.last_name
                          + ', ' + item.loan_application.employee.first_name
                          + ' ' + item.loan_application.employee.middle_name : item.loan_application.name
                        ) : item.credit_to) :
                        (options === 'OTHER RECEIVABLE' || options === 'OFFICE RENTAL SEC DEPOSIT' ? (item.other_receivable != null ? (item.other_receivable.employee != null ? item.other_receivable.employee.last_name
                            + ', ' + item.other_receivable.employee.first_name
                            + ' ' + item.other_receivable.employee.middle_name : item.other_receivable.name
                          ) : item.credit_to) :
                          (options === 'MOTOR FINANCE' ? (item.motor_finance != null ? (item.motor_finance.employee != null ? item.motor_finance.employee.last_name
                              + ', ' + item.motor_finance.employee.first_name
                              + ' ' + item.motor_finance.employee.middle_name : item.motor_finance.name
                          ) : item.credit_to) : item.credit_to)))),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: options === 'AUTO LOAN' ? (item.auto_loan != null ? item.auto_loan.brand_car
                    + '( ' + item.auto_loan.unit + ' )' : 'RETURN FUND - ' + item.description) :
                    (options === 'CA' ? (item.request_data != null ? item.request_data.description : 'RETURN FUND - ' + item.description) :
                      (options === 'LOAN' ? (item.loan_application != null ? item.loan_application.purpose : 'RETURN FUND - ' + item.description) :
                        (options === 'OTHER RECEIVABLE' || options === 'OFFICE RENTAL SEC DEPOSIT' ? (item.other_receivable != null ? item.other_receivable.purpose : 'RETURN FUND - ' + item.description) :
                          (options === 'MOTOR FINANCE' ? (item.motor_finance != null ? item.motor_finance.purpose : '') : 'RETURN FUND - ' + item.description)))),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.particulars,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.duration,
                  alignment: 'left',
                  style: 'tableExample2'
                },
              ])
            })
            info_array = [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Month of: ',
                      {
                        text: this.month_of_items[this.month_of_items.map(function (x) {
                          return x.id;
                        }).indexOf(this.month_of_id)].month_of,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ]
          } else if (this.view_by === 'Deposit Slip') {
            widths = [95, 200, 35, 40, 50]
            payments_array.push([
              {text: 'Name', alignment: 'left', style: 'main_info',},
              {text: 'Description', alignment: 'left', style: 'main_info'},
              {text: 'Amt', alignment: 'left', style: 'main_info'},
              {text: 'Prtclr', alignment: 'left', style: 'main_info'},
              {text: 'Duration', alignment: 'left', style: 'main_info'},
            ])
            this.data_items2.forEach(function (item) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {
                  text: options === 'AUTO LOAN' ? (item.auto_loan != null ? item.auto_loan.name : item.credit_to) :
                    (options === 'CA' ? (item.request_data != null ? item.request_data.payment_for : item.credit_to) :
                      (options === 'LOAN' ? (item.loan_application != null ? (item.loan_application.employee != null ? item.loan_application.employee.last_name
                          + ', ' + item.loan_application.employee.first_name
                          + ' ' + item.loan_application.employee.middle_name : item.loan_application.name
                        ) : item.credit_to) :
                        (options === 'OTHER RECEIVABLE' || options === 'OFFICE RENTAL SEC DEPOSIT' ? (item.other_receivable != null ? (item.other_receivable.employee != null ? item.other_receivable.employee.last_name
                            + ', ' + item.other_receivable.employee.first_name
                            + ' ' + item.other_receivable.employee.middle_name : item.other_receivable.name
                          ) : item.credit_to) :
                          (options === 'MOTOR FINANCE' ? (item.motor_finance != null ? (item.motor_finance.employee != null ? item.motor_finance.employee.last_name
                              + ', ' + item.motor_finance.employee.first_name
                              + ' ' + item.motor_finance.employee.middle_name : item.motor_finance.name
                          ) : item.credit_to) : item.credit_to)))),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: options === 'AUTO LOAN' ? (item.auto_loan != null ? item.auto_loan.brand_car
                    + '( ' + item.auto_loan.unit + ' )' : 'RETURN FUND - ' + item.description) :
                    (options === 'CA' ? (item.request_data != null ? item.request_data.description : 'RETURN FUND - ' + item.description) :
                      (options === 'LOAN' ? (item.loan_application != null ? item.loan_application.purpose : 'RETURN FUND - ' + item.description) :
                        (options === 'OTHER RECEIVABLE' || options === 'OFFICE RENTAL SEC DEPOSIT' ? (item.other_receivable != null ? item.other_receivable.purpose : 'RETURN FUND - ' + item.description) :
                          (options === 'MOTOR FINANCE' ? (item.motor_finance != null ? item.motor_finance.purpose : 'RETURN FUND - ' + item.description) : 'RETURN FUND - ' + item.description)))),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.particulars,
                  alignment: 'left',
                  style: 'tableExample2'
                },
                {
                  text: item.duration,
                  alignment: 'left',
                  style: 'tableExample2'
                },
              ])
            })
            info_array = [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Month of: ',
                      {
                        text: this.month_of_items[this.month_of_items.map(function (x) {
                          return x.id;
                        }).indexOf(this.month_of_id)].month_of,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Deposit ID: ',
                      {
                        text: this.depo_info.id,
                        style: 'main_data', color: 'red',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Date Deposited: ',
                      {
                        text: this.depo_info.date,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Bank: ',
                      {
                        text: this.depo_info.bank.bank_code,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Time Deposited: ',
                      {
                        text: this.depo_info.time,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Amount: ',
                      {
                        text: this.formatPrice(this.depo_info.amount),
                        style: 'main_data', color: 'green',
                      },
                    ],
                  },
                ],
              },
            ]
          }

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'GOODLIFE GROUP OF COMPANIES',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'REMITTANCES OF - ' + this.remittances_category,
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    }
  }
</script>
